.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  border: none;
  font-size: 12px;
  font-weight: 800;
  color: #7e8a95;
  text-transform: uppercase;
}

.btn:hover {
  cursor: pointer;
}

.btn span {
  margin-left: 8px;
}
