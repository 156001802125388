.input {
  height: 42px;
  width: 100%;
  border-radius: 12px;
  background-color: #f5f6fa;
  outline: none;
  border: none;
  padding-left: 10px;
  color: var(--completed-gray);
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 21px;
  border: 1px solid transparent;
}

.input-box > .error {
  /* color: #f40034; */
  border-color: #f40034;
}

.disabled {
  background-color: #dddddd !important;
}

.input::placeholder {
  opacity: 0.6;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  color: #727e8c;
}
