.label {
  color: var(--completed-gray);
  font-size: 14px;
  letter-spacing: 0;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  max-width: -webkit-fill-available;
  /* line-height: 21px; */
}

.input-box {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
}

.flag-box{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
}

.flag-text{
  color: #727E8C;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
}

.label:hover .tooltip {
  visibility: visible;
}

.tooltipContainer{
  position: relative;
  height: 29px;
}

.tooltipContainer:hover .tooltip {
  visibility: visible;
}

.tooltip {
  width: 120px;
  visibility: hidden;
  background-color: black;
  opacity: 0.5;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  top: -30px;
  font-size: 10px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}