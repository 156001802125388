.button {
  outline: none;
  border: none;
  padding: 0;
  box-shadow: none;
  background: none;
}

.button:focus {
  box-shadow: none;
  background: none;
  outline: none;
  border: none;
}
