.filter-button {
  width: 109px;
  height: 40px;
  border-radius: 17px;
  background: rgba(245, 246, 250, 1);
}

.filter-button:hover {
  cursor: pointer;
}

.filter-button-content {
  display: flex;
  align-items: center;
  height: 16px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gray-02);
  margin-left: 16px;
}

.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: white;
  margin-left: 6px;
}

.filter-mark {
  width: 12px;
  height: 8px;
  object-fit: contain;
  margin-bottom: 7px;
}

.filter-button:focus {
  outline: none;
}
