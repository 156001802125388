.navbar{
    padding: 0 16px 0 16px;
}
.title {
    color: var(--completed-gray);
    /* margin-bottom: 14px; */
    font-size: 14px;
    font-weight: 600;
    padding: 16px 0 0 16px;
    margin: 0;
}
.input-box{
    position: relative;
    z-index: 3000;
}
.date{
    margin: 0;
    color: #7E8A95;
    font-size: 14px;
}
.today{
    color: var(--primary-color);
    font-size: 12px;
}

.close {
  position: absolute;
  right: 8%;
  top: 0;
  transform: translate(50%, -100%);
}