.range-container{
    position: relative;
}

.label-container, .value-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: normal;
    color: #727e8c;
}

.label-container{
    margin-bottom: 10px;

}

.value-container{
    padding-top: 8px;
}
.current-position {
    color: #7E8A95;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;

}
.target {
    color: #596BFF;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;


}

.small-text{
    color: #7E8A95;
    mix-blend-mode: normal;
    opacity: 0.7;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;

}