.image-editor {
    display: flex;
    justify-content: center;
    /* width: 100%; */
    flex-wrap: wrap;
    height: 120px;
}

.hidden {
    display: none
}
.image-placeholder {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: white;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* display: flex; */
    margin-bottom: 20px;
    /* border: 0.5px solid #596bff; */
}

.image-text-btn {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
}

.image-text {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #596bff;
    margin-right: 6px;
}

.image-icon {
    transform: scale(0.7)
}
