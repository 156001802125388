.radio, .activeRadio  {
    width: 18px;
    height: 18px;
    border: 1px solid #909EAF;
    border-radius: 100%;
    position: relative;
    flex-shrink: 0;
}

.activeRadio::after {
    content: '';
    width: 14px;
    height: 14px;
    background: var(--primary-color);
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}