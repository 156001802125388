.label{
    color: var(--completed-gray);
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 6px;
}

.input-box{
    position: relative;
    display: flex;
}

.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.selected-value{
    position: absolute;
    top: 11px;
    left: 10px;
    font-size: 12px;
    color: var(--completed-gray);
}

.remove{
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  right: 1rem;
}

.danger {
    border: 1px solid red;
}

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(65 82 125 / 16%) 0px 6px 16px 0px;
  border-radius: 16px;
  z-index: 3000;
  background: #fff;
  outline: none;
  margin-top: 2.5rem;
}

.close-picker {
  position: relative;
  width: 100%;
  height: 24px;
}

.close {
  position: absolute;
  right: 20px;
  top: 5px;
  cursor: pointer;
  height: 24px;
  width: 16px;
}