.table{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;
    color: #727e8c; 
}

.row{
    margin-bottom: 14px;
    border-bottom: 1px solid #EBEEF3;
    height: 45px;
}