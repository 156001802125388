.form-wrapper {
	padding: 23px 0 0 0;
	max-width: 530px;
	width: 100%;
}
.form-field {
	padding: 0 10px;
}
.half-form-field {
	display: inline-block;
	width: 50%;
	padding: 0 10px;
	vertical-align: middle;
}
.input {
	margin-bottom: 24px;
}
.switchInput {
	flex-direction: row-reverse;
	justify-content: flex-end;
	margin-bottom: 20px;
}
.switchInput label {
	margin-left: 10px;
}
.switchActiveInput {
	flex-direction: row-reverse;
	justify-content: flex-end;
	margin-bottom: 24px;
	margin-top: 29px;
}
.switchActiveInput label {
	margin-left: 10px;
}
.buttons-container {
	margin-top: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.content {
  max-width: 688px !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.number-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: nowrap;
}

.number-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  align-items: center;
}

.number-container span {
  font-size: 14px;
  line-height: 19px;
}

.number-container-icons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  /*width: 30%;*/
}

.available-numbers-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.available-numbers-container h3 {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 27px;
  color: #505d70;
}

.available-numbers {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.outline-btn {
  display: flex;
  height: 30px;
  width: 110px;
  outline: none;
  border: 1px solid #596bff;
  color: #596bff;

  font-size: 14px;
  line-height: 19px;
  border-radius: 18px;
  justify-content: center;
  align-items: center;
}

.space {
  margin-right: 10px;
}
.size {
  font-size: 12px;
  color: #727e8c;
  margin-right: 10px;
}

.existing-numbers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 27px;
}
.existing-numbers-container h3 {
  font-size: 20px;
  line-height: 27px;
  color: #505d70;
}

.existing-numbers-container span {
  font-size: 14px;
  line-height: 21px;
  color: #727e8c;
  margin-bottom: 6px;
}

.existing-number {
  height: 21px;
  color: #727e8c;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
}

.get-new-number-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 24px;
}

.get-new-number-container h3 {
  color: #505d70;
  font-size: 20px;
  line-height: 27px;
}

.get-new-number-container span {
  color: #727e8c;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 12px;
}

.input-fields-container {
  display: flex;
  flex-direction: column;
  width: 420px;
  margin-bottom: 12px;
}
.input-fields-container label {
  color: #727e8c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}
.search-btn {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 227px;
  border-radius: 18px;
  background-color: #596bff;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  margin-top: 12px;
}

@media screen and (max-width: 844px) {
  .modal {
    width: 100% !important;
  }

  .input-fields-container > .search-btn {
    width: 100%;
  }
}
