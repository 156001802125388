.badge{ 
    display: flex;
    align-items: center;
    height: 28px;
    color: #fff;
    background: var(--primary-color);
    font-size: 14px;
    padding: 0 10px;
    border-radius: 18px;
}

.remove{
    font-size: 15px;
    color: white;
    padding-left: 8px;
}