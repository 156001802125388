.pill-container {
  border: solid 1px #b2b9bf;
  border-radius: 17px;
  height: 28px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b2b9bf;
  padding: 5px 14px;
  cursor: pointer;
  margin-right: 10px;
}

.active {
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  background-color: #596bff;
  color: white;
}