.container{
    position: relative;
}

.searchField{
    border: none;
    outline: none;
    padding: 15px 15px 0px 15px;
    width: 100%;
    padding-left: 40px;
    color: var(--dark-gray);
}

.searchIcon{
    position: absolute;
    left: 12px;
    top: 14px;
}

.inputField{
    cursor: pointer;
    height: 42px;
    width: 100%;
    border-radius: 12px;
    font-style: italic;
    background-color: #f5f6fa;
    outline: none;
    border: none;
    padding-left: 10px;
    color: var(--completed-gray);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    border: 2px solid transparent;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 40px;
    pointer-events: none;
}

.inputField::placeholder {
    opacity: 0.6;
}

.inputContainer{
    position: relative;
    cursor: pointer;
}

.optionsContainer{
    position: absolute;
    z-index: 110;
    background: white;
    left: 0;
    right: 0;
    box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
    border-radius: 12px;
    max-height: 300px;
    overflow-y: auto;
}

.iconContainer {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
}

.optionBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    font-weight: 400;
}

.optionBox span{
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.optionBox:first-child {
    margin-top: 10px;
}

.optionBox:last-child {
    margin-bottom: 10px;
}

.optionBox:hover {
    cursor: pointer;
    background: rgba(245, 246, 250, 0.6);
}

.radio, .activeRadio  {
    width: 18px;
    height: 18px;
    border: 1px solid #909EAF;
    border-radius: 100%;
    position: relative;
    flex-shrink: 0;
}

.activeRadio::after {
    content: '';
    width: 14px;
    height: 14px;
    background: var(--primary-color);
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hr {
    width: 82%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}