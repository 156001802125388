.button {
  padding: 0;
  outline: none;
  line-height: 0;
  border: none;
  background: none;
  color: var(--primary-color);
}

.button:hover {
  cursor: pointer;
}

.button:focus {
  border: 0;
  outline: none;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
