.white > svg > *,
.white > svg > g > * {
  stroke: white;
  fill: white;
}

.primary > svg > *,
.primary > svg > g > * {
  stroke: var(--primary-color);
  fill: var(--primary-color);
}

.secondary > svg > *,
.secondary > svg > g > * {
  stroke: var(--secondary);
}

.gray > svg > *,
.secondary > svg > g* {
  stroke: var(--gray-text);
}

.red > svg > *,
.red > svg > g > * {
  stroke: red;
  fill: red;
}

.svg-pointer:hover {
  cursor: pointer;
}



.svg-large {
  height: 40px;
  width: 40px;
}

