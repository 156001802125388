.input-box-container {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  color: var(--completed-gray);
  font-size: 14px;
  letter-spacing: 0;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  color: var(--completed-gray);
  font-size: 14px;
  letter-spacing: 0;
  margin-bottom: 8px;
  /* line-height: 21px; */
}
