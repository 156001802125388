.label {
  color: var(--completed-gray);
  font-size: 14px;
  letter-spacing: 0;
  margin-bottom: 6px;
}

.input-box {
  position: relative;
}

.input {
  background-color: #f5f6fa !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.selected-value {
  position: absolute;
  top: 11px;
  left: 10px;
  font-size: 14px;
  color: var(--completed-gray);
}
