.notification-container {
  width: 420px;
  border-radius: 12px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  display: flex;
  position: relative;
  animation-name: enter;
  animation-duration: .3s;
  z-index: 5000;

}

.removing {
  animation-name: exit;
  animation-duration: .300s;
  animation-fill-mode: forwards;
}

@keyframes enter {
  from {
    transform: translateX(420px)
  }
  to {
    transform: translateX(0px)
  }
}

@keyframes exit {
  from {
    transform: translateX(0px)
  }
  to {
    transform: translateX(520px)
  }
}

.notification-icon-container {
  width: 75px;
  border-radius: 12px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.messages-container {
  display: flex;
  flex-direction: column;
  padding: 14px;
  height: auto;
  overflow: hidden;
}

.message {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a3340;
  margin-right: 10px;
}

.description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727e8c;
}

.close-button-container {
  position: absolute;
  top: 6px;
  right: 12px;
}

.close-button-container:hover {
  cursor: pointer;
}

.info-container {
  border: solid 1px #596bff;
}

.info-icon {
  background-color: #596bff;
}

.success-container {
  border: solid 1px #48d7ab;
}

.success-icon {
  background-color: #48d7ab;
}

.warning-container {
  border: solid 1px #ffad00;
}

.warning-icon {
  background-color: #ffad00;
}

.danger-container {
  border: solid 1px #f40034;
}

.danger-icon {
  background-color: #f40034;
}