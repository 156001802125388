.input-box {
  position: relative;
  width: 100%;
}

.suffix{
  position: absolute;
  right: 16px;
  color: var(--completed-gray);
  top: 50%;
  transform: translateY(-50%);
}

.error{
  border: 1px solid red;
}

.arrow{
  position: absolute;
  right: 10px;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.arrow:first-of-type {
  top: 0;
  padding-top: 13px;
}

.arrow:last-of-type {
  bottom: 0;
  padding-bottom: 13px;
}

.number-input {
  height: 42px;
  width: 100%;
  border-radius: 12px;
  outline: none;
  padding: 10px 0 10px 10px;
  border: 1px solid transparent;
  padding-left: 10px;
  color: var(--completed-gray);
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 21px;
  background-color: #f5f6fa !important;
}

.number-input::placeholder {
  opacity: 0.6;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  color: #727e8c;
}

.number-input:disabled {
  background-color: #dddddd;
}

/* Chrome, Safari, Edge, Opera */
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-input {
  -moz-appearance: textfield;
}
