.label {
    color: var(--completed-gray);
    font-size: 14px;
    letter-spacing: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    margin: 0 10px 0 0;
  }

.input {
    display: flex;
    align-items: center;
}