.label {
    color: var(--completed-gray);
    font-size: 14px;
    letter-spacing: 0;
    margin-left: 10px;
    text-overflow: ellipsis;
    width: 60%;
}

.label-container{
    flex-grow: 1;
}

.input-box {
    display: flex;
}

.input-box:hover {
    cursor: pointer;
}

.input-box input[type="file"] {
    display: none;
}

.input-box label {
    margin: 0 !important;
}

.upload-circle {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: #FFFFFF;
    background-size: cover;
    background-position: center;
    display: flex;
    font-size: 26px;
    justify-content: center;
    align-items: center;
    color: var(--completed-gray);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.header {
    color: var(--completed-gray);
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 4px;
    display: "inline-block"
}