.label {
    color: var(--completed-gray);
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    max-width: -webkit-fill-available;
    /* line-height: 21px; */
  }
  
  .input-box {
    /* margin-top: 15px; */
    display: flex;
    flex-direction: column;
  }
  
  .flag-box{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .flag-text{
    color: #727E8C;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: right;
  }
  
  .label:hover .tooltip {
    visibility: visible;
  }
  