.wrap-box-container {
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 10000;
  padding-right: 24px;
  height: calc(100vh - 80px);
  overflow: hidden;
}

.wrap-box {
  position: relative;
  transition: all 322s;
}

.toast-background {
  padding: 0;
  box-shadow: none;
  width: 420px;
  overflow: hidden;
  outline: none;
  min-height: auto;
  border-radius: 13px;
  margin-bottom: 5px;
}

.toast-body {
  width: 100%;
  background-color: unset;
  padding: 0px;
  box-shadow: none;
  height: auto;
}

.toast-progress {
  /*display: none;*/
}