div.permissionGuard {
    display: block;
    background: #ebeef3;
    font-size: 20px;
    padding: 20px;
    border-radius: 10px;
}

.permissionGuard h3 {
    font-weight: 700;
    font-size: 18px;
    color: #455464;
    margin: 0 0 0 10px;
}

.permissionGuard p {
    font-size: 14px;
    color: #455464;
    margin: 0;
}

.permissionGuard .header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}