.navbar {
	padding: 0 16px 0 16px;
}
.title {
	color: var(--completed-gray);
	margin-bottom: 14px;
	font-size: 14px;
	font-weight: 600;
	padding: 16px 0 0 16px;
}
.input-box {
	position: relative;
	z-index: 3000;
}
.date {
	margin: 0;
	color: #7e8a95;
	font-size: 14px;
}
.today {
	color: var(--primary-color);
	font-size: 12px;
}

.buttonGroup {
	width: 100px;
	justify-content: space-evenly;
	display: flex;
}
