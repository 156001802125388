.modalWrapper {
    max-width: fit-content;
    margin: 1.75rem auto;
}

.modalContent {
    background: #fff;
    display: inline-block;
    text-align: left;
    border-radius: 12px;
    width: auto;
}

.header {
    border-radius: 12px 12px 0 0;
    display: flex;
    align-items: center;
    position: relative;
    background: #D5D9FC;
    justify-content: flex-start;
    padding: 15px 20px;
}

.title {
    color: #08136C;
    font-weight: 600;
    text-align: left;
    margin: 0 16px 0 0;
    font-size: 20px;
    line-height: 27px;
}

.close {
    margin-left: auto;
    color: var(--gray-02);
    align-self: flex-end;
    font-size: 25px;
    background: none;
    border: none;
    display: block;
    outline: none;
    line-height: 1;
    font-weight: lighter;
}

.bodyClassName {
    padding: 12px 20px 24px 20px;
}

.noPadding {
    padding: 0;
}
