.tabs {
    height: 32px;
    border-radius: 17px;
    color: #b2b9bf;
    border: 1px solid currentColor;
    display: flex;
    justify-content: space-between;
}

.tab {
    border-radius: 17px;
    font-size: 14px;
    font-weight: bold;
    width: 50%;
    color: #b2b9bf;
}

.active {
    background: #596bff;
    color: white;
}