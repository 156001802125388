.add-new-button {
  width: 153px;
  height: 36px;
  border-radius: 18px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  background-color: var(--background-blue);
  color: white;
  border: 0;
  padding: 0;
  margin-left: 25px;
}

.add-new-button:hover {
  cursor: pointer;
}

.add-new-button-content {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 12px;
}

.plus-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  color: white;
  background-color: var(--darker-light-purple);
  margin-left: 14px;
  line-height: 0;
}

@media (max-width: 750px) {
  .add-new-button {
    font-size: 12px;
  }
  
}