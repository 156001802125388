.dropdown {
  position: relative;
  display: flex;
  width: 140px;
  margin-right: 15px;
  border-radius: 18px;
  background-color: #596bff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.disabled {
  background-color: #909bee;
}

.control {
  display: flex;
  padding: 5px 5px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.placeholder {
  padding-left: 20px;
  padding-right: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu {
  position: absolute;
  left: 0;
  top: 36px;
  z-index: 1;
  width: 140px;
  height: fit-content;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 12px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 4px 0;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #727e8c;
}

.menu div {
  padding: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu div:hover {
  background-color: #596bff;
  color: white;
  cursor: pointer;
}

.active {
  background-color: green;
}

.arrowUp {
  transform: rotate(180deg);
  background-color: #7584ff;
  display: flex;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.arrowDown {
  background-color: #7584ff;
  display: flex;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
