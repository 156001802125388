.container {
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 190px;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  background-color: #ffffff;
  padding: 5px;
  position: relative;
  z-index: 1000;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  /* font */
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #7e8a95;
}

.months-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  border-radius: 6px;
  background-color: #f9fafc;
  margin: 4px;
  /* font */
  font-size: 12px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7e8a95;
}

.month:hover {
  cursor: pointer;
  background-color: var(--indigo);
  color: white;
}

.active {
  font-weight: 600;
  background-color: #596bff;
  color: #ffffff;
}

.chevron-left {
  margin-left: 8px;
}

.chevron-right {
  margin-right: 8px;
}