.input-box {
  position: relative;
  z-index: 3000;
  outline: none;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  padding: 16px;
}

.removeBox{
  background: transparent;
  box-shadow: none;
  border-radius: none;
}

.title {
    color: var(--completed-gray);
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 600;
}

.number-input {
    /* width: 114px; */
    /* height: 42px; */
    margin-top: 15px;
    height: 42px;
    width: 114px;
    border-radius: 12px;
    background-color: #F5F6FA;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.number-input:first-child {
    margin-right: 14px;
}

.number-input > *{
    flex: 1 0 50%
}

.time-input {
    border: none !important;
    outline: none !important;
    font-size: 14px !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: grid;
    place-items: center;
    text-align: center;
    appearance: none;
    background: transparent !important;
    width: 100%;
    cursor: pointer;
    color: var(--completed-gray) !important;
    z-index: 100;
    -moz-appearance: textfield;
}

.time-input::-webkit-outer-spin-button,
.time-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.time-input:focus {
    outline: none;
    -webkit-appearance: none;
}

.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 6px;
    gap:6px;
}

.icon-up {
    transform: rotate(180deg) scale(0.6);
}

.icon-down {
    transform: scale(0.6);
}