.label {
  color: var(--completed-gray);
  font-size: 14px;
  letter-spacing: 0;
}

.blank-label {
  height: 16px;
  width: 1px;
}

.input-box {
  position: relative;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.selected-value {
  position: absolute;
  top: 11px;
  left: 10px;
  font-size: 14px;
  color: var(--completed-gray);
}

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(65 82 125 / 16%) 0px 6px 16px 0px;
  border-radius: 16px;
  z-index: 3000;
  background: #fff;
  outline: none;
  margin-top: 0.5rem;
}

.input-container {
  position: relative;
  display: flex;
}

.close-picker {
  position: relative;
  width: 100%;
  height: 24px;
}

.separator {
  width: 1px;
  background: var(--completed-gray);
  opacity: 0.2;
  border-radius: 6px;
  margin: 16px 0;
}

.remove {
  position: absolute;
  right: 16px;
  outline: none;
  color: var(--gray-02);
  background: none;
  font-size: 20px;
  border: none;
  line-height: 1;
  top: 11px;
}

.close {
  position: absolute;
  right: 15px;
  top: 8px;
  cursor: pointer;
  height: 24px;
  width: 16px;
}