.checkbox-wrapper {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  padding-right: 12px;
}

.checkbox-disabled {
  background-color: var(--background-gray);
  cursor: not-allowed;
}

.checkbox-checked {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: solid 1px var(--background-blue-secondary);
  background-color: var(--background-blue-secondary);
  border: 0;
  padding: 0;
}

.checkbox-wrapper-disabled {
  font-style: italic;
  opacity: .5;
}

.checkbox {
  background-color: transparent;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: solid 1px #7e8a95;
}

.checkbox:focus {
  outline: 0;
}

.check-mark-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-mark-label {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #727e8c;
  margin-right: 10px;
}

.check-mark-checked {
  width: 8px;
  height: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-checked:focus {
  outline: 0;
}

.check-mark {
  display: none;
}
