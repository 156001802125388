.content {
    width: 460px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 }

.text {
    color: #505D70;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 27px;
    text-align: center;
    margin: 20px 0 26px 0;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}