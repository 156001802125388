.scroller-wrapper * {
  scrollbar-color: rgba(0,0,0,.4) transparent;
  scrollbar-width: thin;
}
.scroller-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scroller-wrapper::-webkit-scrollbar-track {
  background: transparent;
}
.scroller-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.4);
  border-radius: 3px;
}