.backdrop{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0, 0.6);
    z-index: 10000;
}

.spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid var(--primary-color); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.centered {
    display: flex;
    flex: 1;
    align-items: center;
}

.maxed-height {
    height: 100%;
}