.input-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
}
.input-field {
  height: 29px;
  width: 100%;
  border-radius: 5px;
  background-color: #EBEEF3;
  opacity: 1;
  color: #62758B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  padding: 5px 5px 5px 36px;
  border: none;
}

.input-field::placeholder {
  opacity: 0.8;
  font-style: italic;
}

.input-field:focus {
  outline: none;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
}