.wrapper {
  border-radius: 12px;
  background-color: #fff;
  padding: 24px;
  overflow: auto;
}

.table {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
}

.th {
  padding: 10px 5px;
  white-space: nowrap;
}

.th:first-child {
  padding-left: 14px;
}

.th:last-child {
  padding-right: 14px;
}

.tr {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #7e8a95;
}

.td {
  position: relative;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.td-popup {
  position: relative;
  overflow: visible;
}

.td-popup:hover {
  cursor: pointer;
}

.td:first-child {
  padding: 0 14px;
}

.tr:nth-child(odd) {
  padding: 0 14px;
  background-color: #f5f6fa;
}

.tr:nth-child(even) {
  background-color: #fff;
}

.empty-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1.17;
  letter-spacing: normal;
  color: #727e8c;
}
