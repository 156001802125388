/* .input {
  height: 42px;
  width: 100%;
  border-radius: 12px;
  background-color: #ffffff;
  outline: none;
  border: none;
  padding-left: 10px;
  color: var(--completed-gray);
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 21px;

  background-color: rebeccapurple;
  color: red;
}

.input::placeholder {
  opacity: 0.6;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  color: #727e8c;
} */

.wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  max-width: 300px;
}

.btn {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  text-align: left;
  height: 42px;
  width: 100%;
  border-radius: 12px;
  background-color: #f5f6fa;
  opacity: 0.6;
  color: #727e8c;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 21px;
}

.btn:hover {
  cursor: pointer;
}

.btn--uploaded {
  color: black;
}

.download {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}

.download:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.icon {
  position: absolute;
  background-color: #727e8c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 12px);
  right: 5px;
  width: 25px;
  height: 25px;
}

.icon:hover {
  background-color: #dddddd;

}

.input {
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  opacity: 0;
}
