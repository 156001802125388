.button {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #fff;
  color: #727e8c;
}

.button:hover {
  cursor: pointer;
}
