.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  min-width: 100px;
  border-radius: 18px;
  border: solid 1px #f5f6fa;
  padding: 0px 3px 0px 6px;
  z-index: 1000;
  background-color: #f5f6fa;
}

.button-container:hover{
  cursor: pointer;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
}

.button-text {
  margin-left: 8px;
  margin-right: 5px;
  height: 16px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--gray-02);
}
