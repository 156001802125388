.container {
  border-radius: 12px;
  background-color: #ffffff;
  border-bottom: 1px solid #f5f6fa;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.container:hover {
  background-color: rgba(245, 246, 250, 0.6);
}

.name {
  width: 100%;
  height: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.31px;
  color: #2a3340;
}

.email {
  width: 100%;
  height: 14px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -0.27px;
  color: #7e8a95;
}

.image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  margin-right: 6px;
}

.detailsbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.input {
  border: none;
  width: 200px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  color: #727e8c;
  padding: 10px;
}
