.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 20px;
}

.pageCount {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    color: #727e8c;
    position: absolute;
    left: 0;

}

.pagination {
    display: flex;
    align-items: center;


}

.paginationLetter{
    font-size: 12px;
    font-weight: bold;
    line-height: 1.67;
    color: #727e8c;
    margin-right: 4px;
    margin-left: 4px;
}

.paginationLetter:hover{
    cursor: pointer;
}

.paginationUnselected {
    opacity: 0.4;
}

.icon {
    margin-left: 15px;
    margin-right: 15px;
}

.icon:hover {
    cursor: pointer;
}