.input {
  width: 100%;
  resize: none;
  border-radius: 12px;
  background-color: #f5f6fa;
  padding: 10px;
  outline: none;
  border: none;
  color: var(--completed-gray);
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 21px;
}

.input::placeholder {
  opacity: 0.6;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  color: #727e8c;
}
