.button {
  min-width: 120px;
  height: 36px;
  padding: 0 20px;
  border-radius: 18px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  background-color: #596bff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
  outline: none;
  border: none;
}

.button:hover {
  cursor: pointer;
}

.button:disabled {
  background-color: #919ae4;
  color: grey;
}

.text {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
