.container {
  display: flex;
  width: 100%;
  position: absolute;
}

.container__list {
  top: 5px;
  z-index: 1;
  position: absolute;
  width: 200px;
  padding: 7px 10px;
  border-radius: 7px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  list-style-type: none;
  background-color: #fff;
}

.container:hover {
  cursor: pointer;
}

.list__item {
  padding: 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list__item:hover {
  background-color: #e2e5e7;
}

.list__item:not(:last-child) {
  border-bottom: 2px solid #f5f6fa;
}
