.box {
    border-radius: 12px;
    padding: 0 12px;
}

.box:hover {
    cursor: pointer;
    background: rgba(245, 246, 250, 0.6);
}

.content {
    display: flex;
    align-items: center;
    padding: 8px 0;
    /* border-bottom: 1px solid var(--gray-02); */
    border-bottom: 1px solid var(--background-gray);
}

.details-container {
    width: 145px;
}

.details-container .primary {
    color: var(--gray-00);
    font-size: 14px;
    letter-spacing: -.31px;
    line-height: 18px;
    margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.details-container .secondary {
    color: var(--gray-02);
    margin: 0;
    font-size: 12px;
    letter-spacing: -.27px;
    line-height: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.icon {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    margin-right: 15px;
    flex-shrink: 0;
}

.primary {
    color: var(--gray-00);
    font-size: 14px;
    letter-spacing: -0.31px;
    line-height: 18px;
    margin: 0;
}

.secondary {
    color: var(--gray-02);
    margin: 0;
    font-size: 12px;
    letter-spacing: -0.27px;
    line-height: 14px;
}

.textLabelActive{
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: #596bff !important;
}

.textBoxActive{
    /* border-radius: 12px; */
    background-color: rgba(245, 246, 250, 0.6);
}