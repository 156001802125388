.buttons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 17px;
  /* border: solid 1px #b2b9bf; */
  height: 36px;
  margin-right: 5px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 36px;
}

.button-container:hover {
  cursor: pointer;
}

.button-container:hover {
  cursor: pointer;
}

.button-text {
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color:rgba(129, 129, 165, 1);
}

.active-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(236, 236, 242, 1);
  color: black;
}

.active-button-text {
  color: black;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
}
