.button-container {
  width: 178px;
  height: 36px;
  border-radius: 18px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  background-color: #596bff;
}

.button-container:hover {
  cursor: pointer;
}

.disabled-button-container {
  width: 178px;
  height: 36px;
  border-radius: 18px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  background-color: #596bff;
  opacity: 0.25;
}

.disabled-button-container:hover {
  cursor: not-allowed;
}

.button-text {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 100px;
}
