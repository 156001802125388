.label {
	color: var(--completed-gray);
	font-size: 14px;
	letter-spacing: 0;
	margin-bottom: 8px;
}

.input-box {
	/* margin-top: 15px; */
	display: flex;
	flex-direction: column;
}
