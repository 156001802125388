
.input-box{
  position: relative;
}

.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.selected-value{
  position: absolute;
  top: 11px;
  left: 10px;
  font-size: 14px;
  color: var(--completed-gray);
}

.pickers-container {
  display: flex;
}