.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 72px;
  height: 36px;
  border-radius: 18px;
  border: solid 1px #f5f6fa;
  padding: 0px;
  z-index: 1000;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
}

.active {
  background-color: #596bff;
}

.active:hover {
  cursor: pointer;
}
