.container {
  height: 26px;
  display: flex;
  flex-direction: row;
  min-width: 50px;
  width: fit-content;
  border-radius: 17px;
  border: solid 1px #b2b9bf;
  position: relative;
  overflow: hidden;
}

.pasive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  border-radius: 13px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b2b9bf;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
}

.pasive:hover {
  cursor: pointer;
}

.active {
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 13px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  background-color: #596bff;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
}

.active:hover {
  cursor: pointer;
}
