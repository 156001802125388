.add {
  color: var(--background-blue);
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  line-height: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.23px;
}

.add:hover {
  cursor: pointer;
}

.add span {
  font-weight: 100;
  font-size: 18px;
  margin-left: 7px;
}
