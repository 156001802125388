.container {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #727e8c;
  margin-bottom: 8px;
}

.radios {
  display: flex;
}

.radio {
  height: fit-content;
  display: flex;
}

.horizontal {
  margin-right: 40px;
  align-items: center;
}

.radio__label {
  font-size: 14px;
  margin: 0;
  margin-left: 7px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #727e8c;
}
