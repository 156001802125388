.spinning-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 36px;
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  margin-right: 12px;
  padding: 10px;
}

.spinning-button span {
  color: #7e8a95;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 6px;
  margin-right: 6px;
}

.spinning-icon-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: #f5f6fa;
}

.hidden {
  display: none;
}

.inputDnd {
  height: 100px;
}

.container {
}

.dropArea {
  border: dashed 2px #505d70 !important;
  margin-top: 20px;
}

.safeUploadDropArea {
  margin: 20px;
}

.dropArea svg, .safeUploadDropArea svg {
  display: none;
}

.fileLabel {
  color: #505d70;
  font-size: 12px;
}

.actions {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileSample a {
  font-size: 12px;
}
