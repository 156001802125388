.input-box {
  position: relative;
}

.error{
  color: #f40034;
  border-color: #f40034
}

.options-box{
  position: absolute;
  z-index: 100;
  max-height: 322px;
  min-width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  margin-top: 5px;
  overflow: auto;
  box-shadow: 0 6px 16px 0 rgba(0,0,0,0.16);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.selected-value {
  position: absolute;
  top: 11px;
  left: 10px;
  font-size: 14px;
  color: var(--completed-gray);
  display: inline-block;
  max-width: 82%;
  clip: rect(-4px, auto, auto, auto);
  white-space: nowrap;
}

.hide-input-text {
  color: transparent !important;
}
