.button {
  color: var(--gray-text);
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  height: 36px;
  border-radius: 18px;
  border: none;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-right: 12px;
}

.button:hover {
  cursor: pointer;
}

.button:focus,
.dark-mode-button:focus {
  outline: none;
}

.button-primary {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #596bff;
}

.button:hover,
.dark-mode-button:hover {
  /*transform: scale(1.01)*/
}

.button:active,
.dark-mode-button:active {
  /*transform: scale(0.98)*/
}

.icon-container {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--background-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  line-height: 1px;
}

.icon-container-primary svg {
  width: 12px;
  height: 12px;
}

.icon-container-primary {
  background-color: #7584ff;
}

.text {
  margin-left: 14px;
  margin-right: 8px;
}

.dark-mode-button {
  color: #7e8a95;
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  height: 36px;
  border-radius: 18px;
  border: none;
  background-color: #f5f6fa;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.dark-icon-container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 8px;
  line-height: 1px;
}
