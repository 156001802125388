.container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.container > img {
  width: inherit;
  height: inherit;
  display: block;
  max-width: 100%;
  object-fit: cover;
  background: #f9f9f9;
}
