.add {
  color: var(--background-blue);
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
}

.add:hover {
  cursor: pointer;
}

.add span {
  font-weight: 100;
  font-size: 18px;
  margin-left: 7px;
}

.icon-background {
  border-radius: 50%;
   background-color: #596BFF;
  background-size: cover;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.icon {
  transform: scale(1.1);
}

.secondary-icon {
  font-weight: 100;
  font-size: 28px !important;
  margin-left: 7px;
  margin-bottom: 3px;
}