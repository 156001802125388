.add-new-button {
  height: 36px;
  border-radius: 18px;
  box-shadow: 0 6px 16px 0 rgba(65, 82, 125, 0.16);
  background-color: var(--background-blue);
  color: white;
  border: 0;
  padding: 0;
}

.save-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  margin-left: 14px;
  margin-right: 14px;
}

.add-new-button:hover {
  cursor: pointer;
}

.add-new-button:focus {
  outline: none;
}

.add-new-button-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  margin-left: 14px;
}

.plus-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  color: white;
  background-color: var(--darker-light-purple);
  margin-left: 8px;
  margin-right: 5px;
  line-height: 0;
}

.plusmark {
  transform: scale(0.8);
}
