.circle-container {
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
}

.circle {
  /* margin: 0 6px; */
  width: 8px;
  height: 8px;
  border-radius: 4px;
  border: solid 0.5px #979797;
  background-color: #ffffff;
}

.active {
  background-color: #596bff;
  border: none;
}